import React, { Suspense } from "react";
import type { LoaderFunction, MetaFunction } from "@remix-run/node";
import { cache } from "~/utils/cache";
import { json, defer } from "@remix-run/node";
import {
  Await,
  Link,
  Outlet,
  useLoaderData,
  useLocation
} from "@remix-run/react";
import { getAvatar, type ParsedAccount } from "~/utils/hive";
import InfoHeader, {
  BackButton,
  InfoHeaderLabel
} from "~/components/InfoHeader";
import DisplayName from "~/components/format/DisplayName";
import AccountName from "~/components/format/AccountName";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBirthdayCake,
  faLink,
  faMapMarkerAlt
} from "@fortawesome/free-solid-svg-icons";
import { ExternalLink } from "~/components/format/ExternalLink";
import { updateAccount } from "~/utils/transactions";
import { EditProfileModal } from "~/components/profile/EditProfileModal";
import classNames from "classnames";
import NotFound from "../$";
import ImageViewer from "~/components/ImageViewer";
import { leocache, type ThreadStats } from "~/utils/leocache";
import { ReferrerModal } from "~/components/profile/ReferrerModal";
import moment from "moment";
import Reputation from "~/components/format/Reputation";
import { useAppStore } from "~/store";
import FollowButton from "~/components/general/FollowButton";
import ListsModal from "~/components/ListsModal";
import SubscribeCreatorButton from "~/components/general/SubscribeCreatorButton";
import { getActiveAccount } from "~/session.server";

export const meta: MetaFunction = ({ data }) => {
  const account = data?.account;
  const profileName =
    account?.posting_json_metadata?.profile?.name || account?.name;

  return [
    { title: `${profileName}'s Profile in INLEO (@${account?.name})` },
    {
      property: "og:title",
      content: `${profileName}'s Profile in INLEO (@${account?.name})`
    }
  ];
};

export const loader: LoaderFunction = async ({ params, request }) => {
  let loaderAccount = await getActiveAccount(request);
  let accountExists = await cache.accountExists(params.account!);

  if (!accountExists) {
    // Data is not in cache; use defer to stream data
    const accountPromise = cache.getAccount(params.account!, true);
    return defer(
      { account: accountPromise },
      {
        headers: { "Cache-Control": "public, max-age=60" }
      }
    );
  } else {
    // Data is in cache; return it immediately
    const account = await cache.getAccount(params.account!, false);
    if (!account?.name) {
      return json({ error: "Account not found" }, { status: 404 });
    }
    return json(
      { account, loaderAccount },
      {
        headers: { "Cache-Control": "public, max-age=60" }
      }
    );
  }
};

interface ProfileData {
  account: ParsedAccount | Promise<ParsedAccount>;
  loaderAccount: String;
}

export default function Profile() {
  const { account, loaderAccount } = useLoaderData<ProfileData>();

  if (!account) return <NotFound />;

  return (
    <React.Fragment>
      <InfoHeader>
        <BackButton />
        <InfoHeaderLabel>
          <Suspense fallback={<div></div>}>
            <Await resolve={account}>
              {accountData => (
                <span>
                  {accountData?.posting_json_metadata?.profile?.name ||
                    accountData?.name}
                </span>
              )}
            </Await>
          </Suspense>
        </InfoHeaderLabel>
      </InfoHeader>
      <Suspense fallback={<div></div>}>
        <Await resolve={account}>
          {accountData => (
            <ProfileContent
              loaderAccount={loaderAccount}
              accountData={accountData}
            />
          )}
        </Await>
      </Suspense>
    </React.Fragment>
  );
}

function ProfileContent({
  accountData,
  loaderAccount
}: {
  accountData: ParsedAccount;
  loaderAccount: String;
}) {
  const [editProfileModal, setEditProfileModal] = React.useState(false);
  const [profileImageViewer, setProfileImageViewer] = React.useState(false);

  const activePage = useLocation().pathname.split("/").reverse()[0];

  const [activeAccount] = useAppStore(store => [store.account.activeAccount]);

  const handleAccountEditProfile = React.useCallback(
    async (data: string) => {
      await updateAccount(accountData.name, data, activeAccount?.name);
      setEditProfileModal(false);
    },
    [accountData.name, activeAccount?.name]
  );

  const {
    displayName,
    about,
    website,
    location,
    birthday,
    isSelf,
    posting_json_metadata
  } = React.useMemo(() => {
    const posting_json_metadata = accountData?.posting_json_metadata;

    const displayName =
      posting_json_metadata?.profile?.name ?? accountData?.name;

    const about = posting_json_metadata?.profile?.about;
    const website = posting_json_metadata?.profile?.website;
    const location = posting_json_metadata?.profile?.location;
    const birthday = posting_json_metadata?.profile?.birthday;

    const isSelf = accountData?.name === loaderAccount;

    return {
      displayName,
      about,
      website,
      location,
      birthday,
      isSelf,
      posting_json_metadata
    };
  }, [accountData, loaderAccount]);

  return (
    <>
      <meta itemProp="dateCreated" content={accountData?.created} />
      <meta itemProp="dateModified" content={accountData?.created} />
      <div
        itemProp="mainEntity"
        itemType="https://schema.org/Person"
        className="h-[17rem] w-full"
        itemScope
      >
        <span className="absolute w-0 h-0 opacity-5 text-xs" itemProp="name">
          {accountData?.name}
        </span>
        <div className="h-52 w-full bg-gray-100 dark:bg-zinc-800 border-b border-pri dark:border-pri-d">
          <img
            alt={`${displayName}'s cover`}
            src={
              posting_json_metadata?.profile?.cover_image ||
              `https://img.inleo.io/u/${accountData.name}/cover`
            }
            height={"13rem"}
            className="h-full w-full object-cover"
          />
        </div>

        <div className="relative w-fit h-fit">
          <div
            className="h-32 w-32 rounded-full overflow-hidden relative -top-16 left-6 outline -outline-offset-1 outline-pri dark:outline-pri-d outline-4 bg-gray-300 dark:bg-gray-500 cursor-pointer hover:brightness-75 transition-filter duration-150"
            onClick={() => setProfileImageViewer(true)}
          >
            <img
              itemProp="image"
              src={getAvatar(accountData?.name, "medium")}
              alt=""
              className="object-cover h-full w-full"
              onError={event => {
                event.currentTarget.onerror = null;
                event.currentTarget.src =
                  posting_json_metadata?.profile?.profile_image;
              }}
            />
          </div>
          <ImageViewer
            visible={profileImageViewer}
            images={[getAvatar(accountData?.name, "large")]}
            currentIndex={0}
            onClose={() => setProfileImageViewer(false)}
          />

          <div className="absolute -right-4 top-9">
            <Reputation
              reputation={accountData.reputation as number}
              className="w-[28px] h-[28px] sm:w-[32px] sm:h-[32px] tbl:w-[32px] tbl:h-[32px] font-black"
            />
          </div>
        </div>
      </div>
      <div className="mt-4 px-4 flex flex-col">
        <div className="flex flex-row justify-between">
          <div className="flex flex-col gap-y-1">
            <DisplayName
              authorName={accountData.name}
              name={displayName || accountData.name}
              className="text-xl"
            />
            <AccountName author={accountData.name} className="text-sm" />
          </div>
          <div className="flex flex-row gap-1.5">
            {isSelf && (
              <button
                type="button"
                title="Edit Profile"
                aria-label="Edit Profile"
                onClick={() => setEditProfileModal(true)}
                className="border cursor-pointer border-pri dark:border-pri-d h-fit px-5 py-2 rounded-full text-pri dark:text-white text-sm font-medium hover:bg-pri-d/10 dark:hover:bg-pri/10 transition-colors duration-150"
              >
                Edit Profile
              </button>
            )}
            {loaderAccount && !isSelf && (
              <SubscribeCreatorButton
                activeAccount={loaderAccount}
                creator={accountData?.name}
              />
            )}
            {loaderAccount && !isSelf && (
              <FollowButton
                activeAccount={loaderAccount}
                user={accountData?.name}
              />
            )}
          </div>
        </div>
        <div className="mt-2 flex items-center text-xs gap-4 text-gray-500 dark:text-gray-400">
          {location && (
            <div className="flex gap-1.5 items-center">
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              {location}
            </div>
          )}
          {birthday && (
            <div className="flex gap-1.5 items-center">
              <FontAwesomeIcon icon={faBirthdayCake} />
              {moment(new Date(birthday.replaceAll(".", "/"))).format("DD MMM")}
            </div>
          )}

          {website && (
            <div className="flex gap-1.5 items-center" itemProp="sameAs">
              <FontAwesomeIcon icon={faLink} />
              <ExternalLink href={website}>{website}</ExternalLink>
            </div>
          )}
        </div>

        <div className="mt-5 text-sm" itemProp="description">
          {about}
        </div>
        <div
          className="pt-6 text-sm flex gap-4"
          itemProp="interactionStatistic"
          itemType="https://schema.org/InteractionCounter"
          itemScope
        >
          <Link
            className="flex items-center gap-2 hover:opacity-80 transition-opacity duration-150"
            to="following"
          >
            <span className="text-lg font-bold">
              {accountData.following || 0}
            </span>
            <span className="text-gray-500 dark:text-gray-400">Following</span>
          </Link>
          <Link
            className="flex items-center gap-2 hover:opacity-80 transition-opacity duration-150"
            to="followers"
          >
            <span className="text-lg font-bold" itemProp="userInteractionCount">
              {" "}
              {accountData.followers || 0}
            </span>
            <span
              className="text-gray-500 dark:text-gray-400"
              itemProp="interactionType"
              content="https://schema.org/FollowAction"
            >
              Followers
            </span>
          </Link>
          <ProfileReferrals accountData={accountData} />
        </div>
        <ThreadStats accountData={accountData} />
      </div>
      <div className="border-t border-pri dark:border-pri-d bg-pri dark:bg-pri-d mt-3 pc:sticky pc:top-[49px] z-[999]">
        <div className="flex flex-1 flex-row px-4 pl-1">
          <Link
            to={`/profile/${accountData.name}`}
            className={classNames("flex py-3 px-3 text-sm", {
              "text-acc dark:text-acc-d font-bold border-b-2 border-acc dark:border-acc-d":
                activePage === accountData.name
            })}
          >
            Threads
          </Link>
          <Link
            to={`/profile/${accountData.name}/shorts`}
            className={classNames("flex py-3 px-3 text-sm", {
              "text-acc dark:text-acc-d font-bold border-b-2 border-acc dark:border-acc-d":
                activePage === "shorts"
            })}
          >
            Shorts
          </Link>
          <Link
            prefetch="intent"
            to={`/profile/${accountData.name}/blog`}
            className={classNames("flex py-3 px-3 text-sm", {
              "text-acc dark:text-acc-d font-bold border-b-2 border-acc dark:border-acc-d":
                activePage === "blog"
            })}
          >
            Blog
          </Link>
          <Link
            prefetch="intent"
            to={`/${accountData.name}/wallet`}
            className="flex py-3 px-3 text-sm font-medium"
          >
            Wallet
          </Link>
          <span className="flex py-3 px-3 text-sm opacity-[.5]">Awards</span>
        </div>
      </div>
      <div className="relative border-t border-pri dark:border-pri-d overflow-hidden">
        <Outlet />
      </div>
      <ListsModal />
      <EditProfileModal
        visibility={editProfileModal}
        setVisibility={(visibility: boolean) => setEditProfileModal(visibility)}
        account={accountData}
        onSubmit={handleAccountEditProfile}
      />
    </>
  );
}

function ProfileReferrals({ accountData }) {
  const [totalReferred, setTotalReferred] = React.useState(0);
  const [referredList, setReferredList] = React.useState([]);

  React.useEffect(() => {
    (async function () {
      const totalReferredResponse = await fetch(
        `https://inleo.io/leoinfra/profile/${accountData?.name}/referred_total`
      );
      const totalReferredText = await totalReferredResponse.text();
      const totalReferredListResponse = await fetch(
        `https://inleo.io/leoinfra/profile/${accountData?.name}/referred_list`
      );
      const totalReferredList = await totalReferredListResponse.json();
      setTotalReferred(Number(totalReferredText || 0));
      setReferredList(totalReferredList || []);
      setRefferersOpen(false);
    })();
  }, [accountData]);

  const [referrerOpen, setRefferersOpen] = React.useState(false);

  return (
    <>
      <div
        onClick={() => setRefferersOpen(true)}
        className="flex items-center gap-2 cursor-pointer hover:opacity-80 transition-opacity duration-150"
      >
        <span className="text-lg font-bold">{totalReferred}</span>
        <span className="text-gray-500 dark:text-gray-400">Referrals</span>
      </div>
      <ReferrerModal
        account={accountData?.name}
        visibility={referrerOpen}
        setVisibility={(visibility: boolean) => setRefferersOpen(visibility)}
        referrerList={referredList}
      />
    </>
  );
}

function ThreadStats({ accountData }) {
  const [stats, setStats] = React.useState<ThreadStats>({
    total_count: 0,
    total_votes_sum: 0,
    total_replies_count: 0
  });

  React.useEffect(() => {
    (async function () {
      const statsData = (await leocache.authorStats(accountData.name)) || {
        total_count: 0,
        total_votes_sum: 0,
        total_replies_count: 0
      };
      setStats(statsData);
    })();
  }, [accountData.name]);

  return (
    <div className="flex flex-row gap-4 text-sm pt-2">
      <div className="flex items-center gap-2">
        <span className="text-lg font-bold">{stats?.total_count || 0}</span>
        <span className="text-gray-500 dark:text-gray-400">Threads</span>
      </div>
      <div className="flex items-center gap-2">
        <span className="text-lg font-bold">
          {stats?.total_replies_count || 0}
        </span>
        <span className="text-gray-500 dark:text-gray-400">Thread Replies</span>
      </div>
      <div className="flex items-center gap-2">
        <span className="text-lg font-bold">{stats?.total_votes_sum || 0}</span>
        <span className="text-gray-500 dark:text-gray-400">Threads Votes</span>
      </div>
    </div>
  );
}
