import { Suspense, lazy, useRef } from "react";
import useOnClickOutside from "~/hooks/useClickOutside";
import { FloatingHTMLOverlay } from "../FloatingHTMLOverlay";
import { SmallAvatar14 } from "../format/SmallAvatar";

const ProfileLink = lazy(() => import("~/components/ProfileLink"));

interface ModalProps {
  visibility: boolean;
  setVisibility: (visibility: boolean) => void;
  referrerList: String[];
  account: String;
}

export function ReferrerModal({
  visibility,
  setVisibility,
  referrerList,
  account
}: ModalProps) {
  const modalRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(modalRef, () => setVisibility(false));

  if (!visibility) return <></>;
  return (
    <FloatingHTMLOverlay
      onClick={ev => ev.stopPropagation()}
      className="bg-overlay z-[1000] flex justify-center py-[14vh]"
      lockScroll
    >
      <div
        ref={modalRef}
        className="animate-reveal-form relative py-4 px-6 flex flex-col gap-y-1 rounded-xl border-xl bg-pri dark:bg-pri-d border-pri dark:border-pri w-10/12 h-fit pc:w-3/12 pc:max-h-fit md:w-6/12 sm:w-8/12 xs:w-full tbl:min-w-[420px]"
      >
        <span className="text-lg font-bold">{account}'s referrals</span>
        {referrerList.length > 0 && (
          <>
            <div className="w-full h-fit p-4 mt-2 text-sm text-zinc-400 bg-pri/[.5] dark:bg-pri-d/[.5] border border-pri/[.5] dark:border-pri-d/[.5] rounded-md focus:outline-none focus:ring-2 focus:ring-pri focus:ring-opacity-50">
              https://inleo.io/signup?referral={account}
            </div>
            <span className="text-sm my-2">
              You can find your own referral code in Settings page.
            </span>
          </>
        )}
        {referrerList.length === 0 && (
          <span className="text-center text-sm py-4 mt-6">
            There is no user registered with {account}'s referral code.
          </span>
        )}
        <div className="pb-4 max-h-[40vh] flex flex-col overflow-y-scroll gap-y-5 crollbar-thumb-acc dark:scrollbar-track-zinc-800/5 scrollbar scrollbar-w-1 scrollbar-track-inherit scrollbar-thumb-w-1 scrollbar-thumb-rounded-full">
          {referrerList.map((accountName, i) => (
            <div key={i} onClick={() => setVisibility(false)}>
              <Suspense fallback={<></>}>
                <ProfileLink accountName={accountName as string}>
                  <div className="px-4 cursor-pointer flex flex-row gap-5 text-center p-2 dark:bg-pri/10 rounded-lg">
                    <SmallAvatar14 author={accountName as string} />
                    <span className="pl-6 text-center justify-center flex items-center">
                      {accountName}
                    </span>
                  </div>
                </ProfileLink>
              </Suspense>
            </div>
          ))}
        </div>
      </div>
    </FloatingHTMLOverlay>
  );
}
